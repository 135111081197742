import en from './en.json';
import es from './es.json';
import it from './it.json';
import fr from './fr.json';
import de from './de.json';
export const messages = {
  en,
  es,
  it,
  fr,
  de
};
