import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-711a42ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XyzTransitionGroup = _resolveComponent("XyzTransitionGroup")!

  return (_openBlock(), _createBlock(_component_XyzTransitionGroup, {
    class: "wrapper-alerts",
    xyz: "fade in-down out-up"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.alerts, (alert) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["alert-box", [alert.type]]),
          key: alert.id,
          onClick: ($event: any) => ($setup.setAlertById(alert.id))
        }, [
          _createElementVNode("div", _hoisted_2, [
            (alert.type == 'correct')
              ? (_openBlock(), _createBlock($setup["IconCheck"], {
                  key: 0,
                  size: 26
                }))
              : (_openBlock(), _createBlock($setup["IconClose"], {
                  key: 1,
                  size: 26
                }))
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(alert.message), 1)
        ], 10, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }))
}