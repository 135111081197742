import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48b5b6cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-holder" }
const _hoisted_2 = { class: "rectangle relative" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "square" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "subtitle" }
const _hoisted_9 = { class: "square" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "subtitle" }
const _hoisted_12 = { class: "rectangle relative" }
const _hoisted_13 = { class: "info" }
const _hoisted_14 = { class: "title" }
const _hoisted_15 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('HomeFeatures.Grid.rectangle_1_title')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('HomeFeatures.Grid.rectangle_1_subtitle')), 1),
        _createVNode(_component_router_link, { to: {name:"SelectTemplate"} }, {
          default: _withCtx(() => [
            _createVNode($setup["AppButton"], {
              class: "custom-button",
              iconComponent: $setup.iconArrowLiteVue,
              iconPosition: "right",
              iconSize: 16
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('HomeFeatures.Grid.rectangle_1_button')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Vue3Lottie, {
        class: "lottie",
        animationData: $setup.lottie
      }, null, 8, ["animationData"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode($setup["iconRocket"], {
        class: "icon-style",
        size: 28
      }),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('HomeFeatures.Grid.square_1_title')), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('HomeFeatures.Grid.square_1_subtitle')), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode($setup["IconCharts"], {
        class: "icon-style",
        size: 28
      }),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('HomeFeatures.Grid.square_2_title')), 1),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('HomeFeatures.Grid.square_2_subtitle')), 1)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('HomeFeatures.Grid.rectangle_2_title')), 1),
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('HomeFeatures.Grid.rectangle_2_subtitle')), 1),
        _createVNode(_component_router_link, { to: {name:"SelectTemplate"} }, {
          default: _withCtx(() => [
            _createVNode($setup["AppButton"], {
              class: "custom-button",
              iconComponent: $setup.iconArrowLiteVue,
              iconPosition: "right",
              iconSize: 16
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('HomeFeatures.Grid.rectangle_2_button')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Vue3Lottie, {
        class: "lottie",
        animationData: $setup.centerLottie
      }, null, 8, ["animationData"])
    ])
  ]))
}