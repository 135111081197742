import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebd72fb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-holder" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info-holder" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "quote" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "project-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.props.image
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "qutoe", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "name", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "position", {}, undefined, true),
          _createElementVNode("span", null, [
            _createTextVNode(" "),
            _renderSlot(_ctx.$slots, "business", {}, undefined, true)
          ])
        ])
      ])
    ])
  ]))
}