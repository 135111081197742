import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_FINANCES } from '@/config/constants';
import { debounce } from 'lodash';
import { FinancesModel } from './types';

const cleanFinancesForm: FinancesModel = {
  revenue_model: '', //Modelo de ingresos.
  use_of_funds: '', //Uso de fondos
  exit_strategy: '', //estrategia de escape
  revenues: [],
  direct_expenses: [],
  gross_profit: [],
  gross_profitx100: [],
  other_expenses: [],
  ebidta: [],
  depreciation: [],
  amortization: [],
  interest_expense: [],
  income_tax_expense: [],
  net_income: [],
  current_assets: '',
  static_assets: '',
  own_funds: '',
  long_liabilites: '',
  short_liabilites: '',
  work_capital: '',
  graphic_base64: ''
};

export const useFinancesStore = defineStore('financesStore', () => {
  const financesForm: Ref<FinancesModel> = useStorage(LOCALE_FORM_FINANCES, cleanFinancesForm);

  const setForm = (newForm, key) => {
    financesForm.value[key] = newForm;
  };
  const setFullForm = data => {
    financesForm.value = data.finances;
  };
  const getForm = () => financesForm.value;

  const setFinancesFormData = debounce(newData => {
    financesForm.value = newData;
  }, 1000);

  const $reset = () => {
    financesForm.value = cleanFinancesForm;
  };

  return {
    financesForm,
    setForm,
    setFinancesFormData,
    setFullForm,
    getForm,
    $reset
  };
});
