import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-238e9173"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-auth" }
const _hoisted_2 = { class: "column-1" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "column-2" }
const _hoisted_5 = { class: "column-structure" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: {name: 'Home'},
        custom: ""
      }, {
        default: _withCtx(({navigate}) => [
          _createElementVNode("div", {
            class: "logo",
            onClick: navigate
          }, "r2business.app", 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('AuthLayout.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}