import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_PRODUCTS } from '@/config/constants';
import { debounce } from 'lodash';
import { ProductsModel } from './types';

const cleanProductsForm: ProductsModel[] = [
  {
    text: '',
    description: '',
    cost: '',
    seal_price: '',
    type: null,
    image: '',
    id: Math.random()
  }
];

export const useProductsStore = defineStore('productsStore', () => {
  const productsForm: Ref<ProductsModel[]> = useStorage(LOCALE_FORM_PRODUCTS, cleanProductsForm);

  const setForm = (newForm, key) => {
    productsForm.value[key] = newForm;
  };
  const setFullForm = data => {
    productsForm.value = data.products;
  };
  const getForm = () => productsForm.value;

  const setProductsFormData = debounce(newData => {
    productsForm.value = newData;
  }, 1000);

  const $reset = () => {
    productsForm.value = cleanProductsForm;
  };

  return {
    productsForm,
    setForm,
    setProductsFormData,
    setFullForm,
    getForm,
    $reset
  };
});
