import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-344dd9dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["HomeNumbersText"], null, {
          title: _withCtx(() => [
            _createTextVNode("10% ")
          ]),
          subtitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('HomeNumbers.subtitle_1')), 1)
          ]),
          _: 1
        }),
        _createVNode($setup["HomeNumbersText"], null, {
          title: _withCtx(() => [
            _createTextVNode("7K ")
          ]),
          subtitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('HomeNumbers.subtitle_2')), 1)
          ]),
          _: 1
        }),
        _createVNode($setup["HomeNumbersText"], null, {
          title: _withCtx(() => [
            _createTextVNode("10+ ")
          ]),
          subtitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('HomeNumbers.subtitle_3')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}