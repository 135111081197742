import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_CLIENT_TARGET } from '@/config/constants';
import { debounce } from 'lodash';
import type { ClientTargetModel } from './types';

const cleanClientTargetForm: ClientTargetModel = {
  gender: [],
  location: '',
  civil_status: [],
  idiom: '',
  formation: [],
  income: [],
  age: [],
  client_idiom: '',
  income_origin: [],
  business_size: [],
  resume: ''
};

export const useClientTargetStore = defineStore('clientTargetStore', () => {
  const clientTargetForm: Ref<ClientTargetModel> = useStorage(LOCALE_FORM_CLIENT_TARGET, cleanClientTargetForm);

  const setForm = (newForm, key) => {
    clientTargetForm.value[key] = newForm;
  };
  const setFullForm = data => {
    clientTargetForm.value = data.client_target;
  };
  const getForm = () => clientTargetForm.value;

  const setClientTargetFormData = debounce((newData, subData?) => {
    if (subData) {
      clientTargetForm.value[subData] = newData;
    } else clientTargetForm.value = newData;
  }, 1000);

  const $reset = () => {
    clientTargetForm.value = cleanClientTargetForm;
  };

  return {
    clientTargetForm,
    setForm,
    setClientTargetFormData,
    setFullForm,
    getForm,
    $reset
  };
});
