import JsFileDownloader from 'js-file-downloader';

export const useDownloadFile = () => {
  const download = (fileBase64, fileName: string) => {
    return new JsFileDownloader({
      url: 'data:application/pdf;base64,' + fileBase64,
      filename: `${fileName}-r2business.app.pdf`
    });
  };
  return {
    download
  };
};
