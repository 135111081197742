import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26b181e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "box-menu"
}
const _hoisted_2 = { class: "menu-links-holder" }
const _hoisted_3 = { class: "link red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XyzTransition = _resolveComponent("XyzTransition")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "menu-holder",
      onClick: $setup.switchMenu
    }, [
      _createVNode($setup["iconMenuVue"])
    ]),
    _createVNode(_component_XyzTransition, { xyz: "fade duration-3" }, {
      default: _withCtx(() => [
        ($setup.openMenu)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "background-overlay",
              onClick: $setup.switchMenu
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_XyzTransition, { xyz: "fade left duration-3" }, {
      default: _withCtx(() => [
        ($setup.openMenu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "close-menu",
                  onClick: $setup.switchMenu
                }, [
                  _createVNode($setup["IconClose"])
                ]),
                _createVNode(_component_router_link, {
                  to: {name: 'MyBPlans'},
                  onClick: $setup.switchMenu
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["link", {selected:_ctx.$route.name =='MyBPlans'}])
                    }, _toDisplayString(_ctx.$t('Dashboard.Navbar.plans')), 3)
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: {name:'UserAccount'},
                  onClick: $setup.switchMenu
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["link", {selected:_ctx.$route.name =='UserAccount'}])
                    }, _toDisplayString(_ctx.$t('Dashboard.Navbar.new_plan')), 3)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", {
                  class: "flex gap-2 items-center cursor-pointer red",
                  onClick: $setup.logout
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Dashboard.Navbar.logout')), 1),
                  _createVNode($setup["IconLogout"], { class: "text-red-500 rotate-180" })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}