import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { USER_TOKEN } from '@/config/constants';
import { useAlertsStore } from './alertsStore';
import { useFormStore } from './FormStore';
export const useAuthStore = defineStore('AuthStore', () => {
  const alerts = useAlertsStore();
  const formStore = useFormStore();
  const userIsLoged = ref(false);
  const userToken: Ref<string> | null = useStorage(USER_TOKEN, null);

  const setUserToken = (token: string) => {
    localStorage.setItem(USER_TOKEN, token);
    userIsLoged.value = true;
  };
  const logout = () => {
    userToken.value = null;
    localStorage.setItem(USER_TOKEN, null);
    userIsLoged.value = false;
    formStore.$reset();
    alerts.createAlert('invalid', 'logout');
    if (userToken.value) logout();
  };
  return { userIsLoged, userToken, setUserToken, logout };
});
