import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba051576"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["HomeHero"], { id: "start" }),
    _createVNode($setup["HomeNumbers"], { id: "stats" }),
    _createVNode($setup["HomeFeatures"], { id: "features" }),
    _createVNode($setup["HomePricing"], { id: "pricing" }),
    _createVNode($setup["HomeMagazine"], { id: "magazine" }),
    _createVNode($setup["HomeTestimony"], { id: "testimony" }),
    _createVNode($setup["HomeGetStarted"], { id: "getStarted" })
  ]))
}