import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_DAFO } from '@/config/constants';
import { debounce } from 'lodash';
import { DAFOModel } from './types';

const cleanDafoForm: DAFOModel = {
  debilidades: [],
  fortalezas: [],
  oportunidades: [],
  amenazas: [],
  resume: ''
};

export const useDafoStore = defineStore('dafoStore', () => {
  const dafoForm: Ref<DAFOModel> = useStorage(LOCALE_FORM_DAFO, cleanDafoForm);

  const setForm = (newForm, key) => {
    dafoForm.value[key] = newForm;
  };
  const setFullForm = data => {
    dafoForm.value = data.dafo;
  };
  const getForm = () => dafoForm.value;

  const setDafoFormData = debounce(newData => {
    dafoForm.value = newData;
  }, 1000);

  const $reset = () => {
    dafoForm.value = cleanDafoForm;
  };

  return {
    dafoForm,
    setForm,
    setDafoFormData,
    setFullForm,
    getForm,
    $reset
  };
});
