import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { useStorage } from '@vueuse/core';

import App from './App.vue';
import router from './router';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import { setConfi18n } from './plugins/i18n';
import { LOCALE_ID_LANG } from '@/config/constants';
import Vue3Lottie from 'vue3-lottie';
import { useI18n } from 'vue-i18n';
import createContainerRepositories from './repositories/repository.container';
import './style.scss';

const app = createApp(App);
const pinia = createPinia();
useStorage(LOCALE_ID_LANG, window.navigator.language.slice(0, 2).toLowerCase() || 'es');
pinia.use(({ store }) => {
  const { t } = useI18n();
  store.$router = markRaw(router);
  store.$t = t;
});

app.config.globalProperties.window = window;
app.use(pinia).use(createContainerRepositories).use(setConfi18n()).use(router).use(VueAnimXyz).use(Vue3Lottie).mount('#app');
