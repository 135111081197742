import { UsersRepository } from '../repositories/users/users.repository';
import { inject } from 'vue';
import { useUserStore } from '@/stores/userStore';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router';
import { UserModel } from '../models/user.model';
import { useAlertsStore } from '@/stores/alertsStore';
import { useI18n } from 'vue-i18n';
import { BusinessPlanRepository } from '@/repositories/business_plan/business_plan.repository';
import { useFormStore } from '@/stores/FormStore';
import { useTemplateConfgigStore } from '@/stores/templateConfStore';
import { PaymentsRepository } from '@/repositories/payments/payments.repository';
import { useDownloadFile } from './useDownloadFile';
import { CustomAxiosResponse } from '../repositories/axios.factory';
import { FormModel } from '../stores/types';

export const UseUserActions = () => {
  const UserRepository = inject<UsersRepository>('UserRepository');
  const BusinessPlanRepository = inject<BusinessPlanRepository>('BusinessPlanRepository');
  const PaymentsRepository = inject<PaymentsRepository>('PaymentsRepository');
  const downloadFile = useDownloadFile();
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const alertsStore = useAlertsStore();
  const router = useRouter();
  const formStore = useFormStore();
  const templateConfigStore = useTemplateConfgigStore();
  const { t } = useI18n();

  const getMe = async () => {
    const userDataResponse = await UserRepository.getMe();
    if (userDataResponse.isError) {
      logout();
    } else {
      userStore.setDataUser(userDataResponse.data);
      return userDataResponse.data;
    }
  };

  const updateContactInfo = async (userData: Partial<UserModel>) => {
    const { isError } = await UserRepository.updateContactData(userData);
    if (isError) {
      alertsStore.createAlert('invalid', t('Alerts.info_not_updated'));
    } else {
      alertsStore.createAlert('correct', t('Alerts.info_ok'));
    }
    return await getMe();
  };

  const updateLanguage = async (userLanguage: string) => {
    if (userStore.user_email) {
      const { isError } = await UserRepository.updateMeLanguage(userLanguage);
      if (isError) {
        alertsStore.createAlert('invalid', t('Alerts.idiom_not_updated'));
      } else {
        //TODO CAMBIAR EL IDIIOMA DE LA APLICACION PRIMERO PARA ENVIAR EN MENSAJE CON EL IDIOMA CORRECTO
        alertsStore.createAlert('correct', t('Alerts.idiom_ok'));
      }
      return await getMe();
    }
  };

  const updateMePassword = async (last_password: string, new_password: string) => {
    const { isError, error } = await UserRepository.updatePassword(last_password, new_password);
    if (isError) {
      if (error == 'incorrect_last_password') {
        alertsStore.createAlert('invalid', t('Alerts.pass_incorrect'));
      } else {
        alertsStore.createAlert('invalid', t('Alerts.pass_not_updated'));
      }
    } else {
      alertsStore.createAlert('correct', t('Alerts.pass_ok'));
    }
  };

  const getAllBusinessPlan = async () => {
    const { data, isError, error } = await BusinessPlanRepository.getBusinessPlans();
    if (isError && error !== 'no_business_plan_for_given_user') alertsStore.createAlert('invalid', t('Alerts.unknow_error'));
    else return data ? data : [];
  };

  const getFormedData = () => {
    const formData: FormModel = formStore.getForm();
    const templateConfig = templateConfigStore.getForm();
    return JSON.stringify({
      TemplateConf: templateConfig,
      ProfileData: formData
    });
  };
  const saveBusinessPlan = async () => {
    const formData = formStore.getForm();
    const business_plan_content = getFormedData();
    const cover = formStore.cover;
    const business_plan_name = formData.executiveSummary.com_name;
    if (authStore.userIsLoged) {
      if (formStore.formId && formStore.formId !== 'null') {
        const { data, isError } = await BusinessPlanRepository.updateBusinessPlan(formStore.formId, {
          business_plan_name,
          business_plan_content
        });
        if (isError) {
          alertsStore.createAlert('invalid', t('Alerts.error_ocurred'));
          formStore.$reset();
        } else return data;
      } else {
        const { data, isError } = await BusinessPlanRepository.createNewBusinessPlan({
          business_plan_name,
          business_plan_content,
          business_plan_cover: cover
        });

        if (isError) alertsStore.createAlert('invalid', t('Alerts.error_ocurred'));
        else {
          await formStore.setId(data._id);
          return data;
        }
      }
    }
  };

  const updateCover = async () => {
    if (formStore.formId && formStore.formId !== 'null' && authStore.userIsLoged) {
      const { data, isError } = await BusinessPlanRepository.updateBusinessPlan(formStore.formId, {
        business_plan_cover: formStore.cover
      });
      if (isError) {
        alertsStore.createAlert('invalid', t('Alerts.cover_not_saved'));
        formStore.$reset();
      }
    }
  };
  const editBusinesPlan = business_plan_data => {
    const { TemplateConf, ProfileData } = JSON.parse(business_plan_data.value.business_plan_content);
    formStore.setFullForm(ProfileData);
    formStore.setId(business_plan_data.value._id);
    templateConfigStore.setFullForm(TemplateConf);
    router.push({ name: 'SelectTemplate' });
  };

  const duplicateBusinessPlan = async id => {
    const { data, isError, error } = await BusinessPlanRepository.duplicateBusinessPlan(id);
    if (isError) alertsStore.createAlert('invalid', t('Alerts.error_ocurred'));
    else return data;
  };

  const downloadBusinessPlan = async (id: string, business_plan_cover?: string) => {
    const { data, isError, error } = await BusinessPlanRepository.downloadBusinessPlan(id);
    if (!isError) {
      await downloadFile.download(data.business_plan_base_64_pdf, data.business_plan_name);
      router.push({ name: 'MyBPlans' });
    } else {
      console.log('error', error);
      formStore.setPreview(business_plan_cover);
      router.push({ name: 'Register' });
    }
  };
  const sendDownloadEmail = async (email_to_send, business_plan_id): Promise<CustomAxiosResponse<string>> => {
    return await BusinessPlanRepository.sendDownloadEmail(email_to_send, business_plan_id);
  };
  const deleteBusinessPlan = async id => {
    const { data, isError, error } = await BusinessPlanRepository.deleteBusinessPlan(id);
    if (isError) alertsStore.createAlert('invalid', t('Alerts.error_ocurred'));
    else return data;
  };
  const changePassword = async (oldPassword, newPassword) => {
    const { data, isError, error } = await UserRepository.updatePassword(oldPassword, newPassword);
    if (isError) return error;
    else return 'password_changed';
  };
  const recoverPassword = async email => {
    const { data, isError, error } = await UserRepository.startRecoverPaswordByMail(email);
    if (isError) return error;
    else return data; //'password-recovery-email-sended'
  };
  const verifyPasswordRecoveryId = async paswordRecoveryId => {
    const { data, isError, error } = await UserRepository.verifyRememberPaswordKey(paswordRecoveryId);
    if (isError) return error;
    else return data; //recovery_data_valid
  };

  const resetPaswordWithId = async (newPassword: string, paswordRecoveryId: string) => {
    const { data, isError, error } = await UserRepository.resetPaswordRecovery(newPassword, paswordRecoveryId);
    if (isError) return error;
    else return data; //password_changed_succesfully
  };

  const getPayments = async () => {
    return await PaymentsRepository.getPayments();
  };
  const stopSubsciption = async () => {
    return await PaymentsRepository.stopSubscription();
  };

  const logout = () => {
    authStore.logout();
    userStore.$reset();
    router.replace({ name: 'Home' });
  };

  const loginGoogle = async (data: string) => {
    return await UserRepository.loginGoogle(data);
  };

  return {
    getMe,
    logout,
    updateContactInfo,
    updateMePassword,
    updateLanguage,
    getAllBusinessPlan,
    saveBusinessPlan,
    editBusinesPlan,
    duplicateBusinessPlan,
    deleteBusinessPlan,
    getFormedData,
    changePassword,
    recoverPassword,
    verifyPasswordRecoveryId,
    resetPaswordWithId,
    getPayments,
    stopSubsciption,
    loginGoogle,
    downloadBusinessPlan,
    sendDownloadEmail,
    updateCover
  };
};
