import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58ec787a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "features-holder-title" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AppChimp"], { class: "mt-1" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "chimp", {}, undefined, true)
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
    ])
  ]))
}