import AuthLayoutVue from '@/layouts/AuthLayout.vue';
import PublicLayoutVue from '@/layouts/PublicLayout.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import BuilderLayout from '@/layouts/BuilderLayout.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import { useAuthStore } from '@/stores/authStore';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: PublicLayoutVue,
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: {
          redirectIsAuth: false
        }
      },
      {
        path: '/terms',
        name: 'TermsAndConditions',
        component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditionsView.vue')
      },
      {
        path: '/cookies',
        name: 'CookiesPolicy',
        component: () => import(/* webpackChunkName: "CookiesPolicy" */ '../views/CookiesPolicyView.vue')
      }
    ]
  },
  {
    path: '/',
    component: AuthLayoutVue,
    children: [
      {
        path: '/signin',
        name: 'SignIn',
        component: () => import(/* webpackChunkName: "SignIn" */ '../views/AuthSignInView.vue')
      },

      {
        path: '/recover-password',
        name: 'RecoverPassword',
        component: () => import(/* webpackChunkName: "RecoveryPassword" */ '../views/AuthRecoveryPasswordView.vue')
      },
      {
        path: '/recovery-password/:id',
        name: 'RecoveryPassword',
        component: () => import(/* webpackChunkName: "RecoveryPassword" */ '../views/AuthRecoverPaswordWithIdView.vue')
      }
    ],
    meta: {
      redirectIsAuth: true
    }
  },
  {
    path: '/builder',
    component: BuilderLayout,
    children: [
      {
        path: 'select-template',
        name: 'SelectTemplate',
        component: () => import(/* webpackChunkName: "SelectTemplate" */ '../views/BuilderSelectTemplateView.vue')
      },
      {
        path: 'form',
        name: 'BuilderForm',
        component: () => import(/* webpackChunkName: "BuilderForm" */ '../views/BuilderFormView.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "Register" */ '../views/RegisterPaymentView.vue')
      }
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'my-plans',
        name: 'MyBPlans',
        component: () => import(/*webpackChuckName:"MyBPlans"*/ '../views/DashboardPlansView.vue')
      },
      {
        path: 'account',
        name: 'UserAccount',
        component: () => import(/*webpackChuckName:"UserAccount"*/ '../views/DashboardUserAccountView.vue')
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.MAIN_URL),
  routes,
  scrollBehavior(to) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: 20
          });
        } else {
          resolve({
            top: 0,
            behavior: 'smooth'
          });
        }
      }, 1);
    });
  }
});
router.beforeEach((to, from, next) => {
  const AuthStore = useAuthStore();
  if (to.meta.requiresAuth && !AuthStore.userIsLoged) return next({ name: 'SignIn' });
  else if (to.meta.redirectIsAuth && AuthStore.userIsLoged) return next({ name: 'MyBPlans' });

  return next();
});
export default router;
