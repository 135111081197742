import { inject } from 'vue';
import { ProductsRepository } from '../repositories/products.repository.ts/products.repository';
import { SellProductModel } from '../stores/types';

export const useGetProduct = async (): Promise<SellProductModel> => {
  const productsRepository = inject<ProductsRepository>('ProductsRepository');
  const product = await productsRepository.getProducts();
  if (product.isError) {
    console.error("Couldn't retrive the product by country. Using default..");
  } else {
    return product.data;
  }
};
