import { BusinessPlanModel, CreateBusinessPlanModel } from '@/models/businness_plan.model';
import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';

interface responseDownload {
  business_plan_base_64_pdf: string;
  business_plan_name: string;
}

export class BusinessPlanRepository extends AxiosFactory {
  constructor() {
    super('/business_plan');
  }

  async getBusinessPlans(): Promise<CustomAxiosResponse<BusinessPlanModel[]>> {
    return await this.axiosApi.get('/');
  }

  async createNewBusinessPlan(data: CreateBusinessPlanModel): Promise<CustomAxiosResponse<BusinessPlanModel>> {
    // adapt the call
    return await this.axiosApi.post('/', {
      ...data,
      business_plan_status: 'active'
    });
  }
  async updateBusinessPlan(business_plan_id: string, data: Partial<BusinessPlanModel>): Promise<CustomAxiosResponse<BusinessPlanModel>> {
    return await this.axiosApi.put('/', {
      id_list: [business_plan_id],
      business_plan_data: data
    });
  }

  async duplicateBusinessPlan(business_plan_id: string): Promise<CustomAxiosResponse<BusinessPlanModel>> {
    return await this.axiosApi.post('/duplicate', { business_plan_id });
  }

  async deleteBusinessPlan(business_plan_id: string): Promise<CustomAxiosResponse<BusinessPlanModel>> {
    return await this.axiosApi.delete(`/${business_plan_id}`);
  }
  async downloadBusinessPlan(business_plan_id: string): Promise<CustomAxiosResponse<responseDownload>> {
    return await this.axiosApi.get(`/download/${business_plan_id}`);
  }
  async sendDownloadEmail(email_to_send: string, business_plan_id: string): Promise<CustomAxiosResponse<string>> {
    return await this.axiosApi.post('/send-by-email', { business_plan_id, email_to_send });
  }
}
