import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66348eaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-container" }
const _hoisted_2 = { class: "group-links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_XyzTransitionGroup = _resolveComponent("XyzTransitionGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: {name: 'MyBPlans'} }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["link", {selected:_ctx.$route.name =='MyBPlans'}])
          }, _toDisplayString(_ctx.$t('Dashboard.Navbar.plans')), 3)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: {name:'UserAccount'} }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["link", {selected:_ctx.$route.name =='UserAccount'}])
          }, _toDisplayString(_ctx.$t('Dashboard.Navbar.new_plan')), 3)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "group-buttons w-fit logout-button",
      onClick: $setup.logout
    }, [
      _createVNode(_component_XyzTransitionGroup, {
        class: "flex gap-2 p-2 cursor-pointer",
        onMouseenter: _cache[0] || (_cache[0] = ($event: any) => ($setup.doAprr())),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($setup.unAppear())),
        xyz: "rigths"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["IconLogout"], { class: "text-red-500 rotate-180" }),
          ($setup.appr)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["logout-letters", [$setup.appr]])
              }, _toDisplayString(_ctx.$t('Dashboard.Navbar.logout')), 3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}