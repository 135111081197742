import { defineStore } from 'pinia';

export interface AlertModel {
  id: number;
  type: 'correct' | 'invalid';
  message: string;
}

export const useAlertsStore = defineStore('AlertsStore', {
  state: () => {
    return {
      alerts: [] as AlertModel[],
      id: 0
    };
  },
  getters: {},
  actions: {
    createAlert(type: AlertModel['type'], message: string) {
      this.alerts.push({ type, message, id: this.id });
      this.setLimitToDelete(this.id);
      this.id += 1;
    },
    setLimitToDelete(id: number) {
      setTimeout(() => {
        this.setAlertById(id);
      }, 3000);
    },

    setAlertById(id: number) {
      for (let i = 0; i < this.alerts.length; i++) {
        if (this.alerts[i].id == id) this.alerts.splice(i, 1);
      }
    }
  }
});
