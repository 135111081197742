import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.list.length)
    ? (_openBlock(), _createBlock($setup["Carousel"], {
        key: 0,
        "items-to-show": $setup.itemsToShow,
        ref: "myCarousel",
        transition: 1100
      }, {
        addons: _withCtx(() => [
          (!$setup.extendedCarousel || $setup.width < 1024)
            ? (_openBlock(), _createBlock($setup["Page"], { key: 0 }))
            : _createCommentVNode("", true),
          (!$setup.extendedCarousel || $setup.width < 1024)
            ? (_openBlock(), _createBlock($setup["Navigation"], { key: 1 }))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (item, index) => {
            return (_openBlock(), _createBlock($setup["Slide"], {
              key: "slide"+index
            }, {
              default: _withCtx(() => [
                _createVNode($setup["HomeTestimonyCarouselSlide"], {
                  image: $setup.mokedImages[index]
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  qutoe: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.quote), 1)
                  ]),
                  name: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  position: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.position), 1)
                  ]),
                  business: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.business), 1)
                  ]),
                  _: 2
                }, 1032, ["image"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["items-to-show"]))
    : _createCommentVNode("", true)
}