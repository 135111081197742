import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be0aa182"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "wrapper-label"
}
const _hoisted_3 = { class: "wrapper-select" }
const _hoisted_4 = {
  key: 0,
  class: "selected"
}
const _hoisted_5 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_6 = { class: "perfect-scroll-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["BaseInputSkeleton"], {
    "input-active": $setup.showSelectOptions,
    disabled: $props.disabled,
    inputColor: $setup.inputColor
  }, {
    label: _withCtx(() => [
      _createVNode($setup["AppInputLabel"], {
        class: _normalizeClass(['text-'+$setup.labelColor])
      }, {
        default: _withCtx(() => [
          ($props.popHoverText == null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.label), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, _toDisplayString($setup.label), 1),
                _createVNode($setup["AppPopHover"], null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString($props.popHoverText), 1)
                  ]),
                  _: 1
                })
              ]))
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["AppMenuFloat"], {
          top: $setup.menuFloatTopPosition,
          bottom: $setup.menuFloatBottomPosition,
          "position-x": "left-0",
          width: "w-full",
          disabled: $props.disabled,
          "show-menu": $setup.showSelectOptions,
          onIsVisibleMenuFloat: _cache[0] || (_cache[0] = (isVisible) => $setup.showSelectOptions = isVisible)
        }, {
          "action-button": _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["group-select", {disabled: $props.disabled, [$setup.modelValue?.text]:$props.eachRowStyle }])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["input", [$setup.inputSize]])
              }, [
                ($setup.modelValue)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.modelValue?.text), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($setup.placeholder || _ctx.$t('Form.select')), 1))
              ], 2),
              _createVNode($setup["IconArrowLite"], {
                class: "icon-arrow",
                size: $setup.iconSize
              }, null, 8, ["size"])
            ], 2)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["PerfectScrollbar"], { options: {suppressScrollX: false, wheelPropagation: false, wheelSpeed:.3} }, {
                default: _withCtx(() => [
                  ($setup.listOptions.length && $setup.listOptions)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.listOptions, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", null, [
                          (item.text && item.id !== $props.exclude)
                            ? (_openBlock(), _createBlock($setup["AppInputSelectRow"], {
                                text: item.text,
                                key: item.id,
                                onClick: ($event: any) => ($setup.updateModelValue(item)),
                                "is-selected":  $setup.modelValue?.id == item.id,
                                class: _normalizeClass({ 'disable-unselect': !$setup.enableUnselect, [item.text]:$props.eachRowStyle }),
                                "show-select-circle": $props.showSelectCircle
                              }, null, 8, ["text", "onClick", "is-selected", "class", "show-select-circle"]))
                            : (item.id !== $props.exclude)
                              ? (_openBlock(), _createBlock($setup["AppInputSelectRowEmpty"], {
                                  key: 1,
                                  text: "(en blanco)"
                                }))
                              : _createCommentVNode("", true)
                        ]))
                      }), 256))
                    : (_openBlock(), _createBlock($setup["AppInputSelectRowEmpty"], {
                        key: 1,
                        text: "..."
                      }))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["top", "bottom", "disabled", "show-menu"])
      ])
    ]),
    _: 1
  }, 8, ["input-active", "disabled", "inputColor"]))
}