import { PaymentModel } from '@/models/payment.model';
import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';

export class PaymentsRepository extends AxiosFactory {
  constructor() {
    super('/payments');
  }

  async createPaymentIntent(): Promise<CustomAxiosResponse<Record<string, unknown>>> {
    return await this.axiosApi.post('/');
  }

  async getPayments(): Promise<CustomAxiosResponse<PaymentModel[]>> {
    return await this.axiosApi.get('/');
  }
  async stopSubscription(): Promise<CustomAxiosResponse<string>> {
    return await this.axiosApi.post('/stop');
  }
}
