import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-580163d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "pricing-holder" }
const _hoisted_3 = { class: "pricing-info" }
const _hoisted_4 = { class: "pricing basic" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "price" }
const _hoisted_8 = { class: "pricing pro" }
const _hoisted_9 = { class: "hat-pro" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "subtitle" }
const _hoisted_12 = { class: "price" }
const _hoisted_13 = { class: "check-list" }
const _hoisted_14 = { class: "check-list" }
const _hoisted_15 = { class: "check-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.priceTrialFormated && $setup.priceSubscriptionFormated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["HomeSectionTitles"], null, {
            chimp: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('HomePricing.chimp')), 1)
            ]),
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('HomePricing.title')), 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('HomePricing.subtitle')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('HomePricing.basic.title')), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('HomePricing.basic.subtitle')), 1),
              _createElementVNode("div", _hoisted_7, [
                _createTextVNode(_toDisplayString($setup.product.currency_symbol) + " " + _toDisplayString($setup.priceTrialFormated) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('HomePricing.basic.once')), 1)
              ]),
              _createVNode($setup["AppButtonOutlined"], {
                class: "button-outline-style",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({name:"SelectTemplate"})))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('HomePricing.basic.button')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, "✨ " + _toDisplayString(_ctx.$t('HomePricing.pro.hat')), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('HomePricing.pro.title')), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('HomePricing.pro.subtitle')), 1),
              _createElementVNode("div", _hoisted_12, [
                _createTextVNode(_toDisplayString($setup.product.currency_symbol) + " " + _toDisplayString($setup.priceSubscriptionFormated), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('HomePricing.pro.month')), 1)
              ]),
              _createVNode($setup["AppButton"], {
                class: "button-style",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({name:"SelectTemplate"})))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('HomePricing.pro.button')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_13, [
                _createVNode($setup["IconCheck"], { size: 16 }),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('HomePricing.pro.plus_1')), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode($setup["IconCheck"], { size: 16 }),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('HomePricing.pro.plus_2')), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode($setup["IconCheck"], { size: 16 }),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('HomePricing.pro.plus_3')), 1)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}