import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_MARKETING } from '@/config/constants';
import { debounce } from 'lodash';
import { MarketingModel } from './types';

const cleanMarketingForm: MarketingModel[] = [
  {
    productName: '',
    platform: [],
    conventional: [],
    digital: [],
    cost: '',
    return: '',
    id: Math.random()
  }
];

export const useMarketingStore = defineStore('marketingStore', () => {
  const marketingForm: Ref<MarketingModel[]> = useStorage(LOCALE_FORM_MARKETING, cleanMarketingForm);

  const setForm = (newForm, key) => {
    marketingForm.value[key] = newForm;
  };
  const setFullForm = data => {
    marketingForm.value = data.marketing;
  };
  const getForm = () => marketingForm.value;

  const setMarketingFormData = debounce(newData => {
    marketingForm.value = newData;
  }, 1000);

  const $reset = () => {
    marketingForm.value = cleanMarketingForm;
  };

  return {
    marketingForm,
    setForm,
    setMarketingFormData,
    setFullForm,
    getForm,
    $reset
  };
});
