import { createI18n } from 'vue-i18n';
import { LOCALE_ID_LANG } from '@/config/constants';
import { messages } from '../locales/locals';
import { nextTick } from 'vue';

const loadedLanguages = ['en']; // our default language that is preloaded
export const availableLanguages = ['es', 'en', 'it', 'de', 'fr' /*'it', 'nl', 'no', 'pl', 'pt', 'ro', 'sv'*/]; //

export const i18n = createI18n({
  warnHtmlInMessage: false, //eliminamos el warning en consola al utilizar html directamente en los localses*.json
  locale: 'es',
  allowComposition: true,
  escapeParameterHtml: true,
  fallbackLocale: 'en',
  messages
});

export function setConfi18n() {
  const navlanguage = localStorage.getItem(LOCALE_ID_LANG)?.toLowerCase() || navigator.language.slice(0, 2).toLowerCase();
  loadLocaleMessages(navlanguage);
  return i18n;
}

export async function loadLocaleMessages(locale) {
  locale = locale.toLowerCase();

  /* @ts-ignore */
  if (i18n.global.locale === locale || loadedLanguages.includes(locale)) {
    _setI18nLanguage(locale);
  } else {
    const messages = await import(/* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    loadedLanguages.push(locale);
    _setI18nLanguage(locale);
  }
}

function _setI18nLanguage(lang) {
  i18n.global.locale = lang.toLowerCase();
  document.querySelector('html').setAttribute('lang', lang);
  document.querySelector("head [http-equiv='Content-Language']").setAttribute('content', lang);
  localStorage.setItem(LOCALE_ID_LANG, lang.toLowerCase());
  nextTick();
}
