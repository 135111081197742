import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc1cf2be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-link" }
const _hoisted_2 = {
  key: 0,
  class: "check"
}
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["row-link", [{'active': $setup.isSelected, 'sleep': $setup.timerToHoverClass}]])
    }, [
      ($setup.showSelectCircle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.text), 1)
    ], 2)
  ]))
}