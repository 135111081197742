import { UserModel, UserToken } from '@/models/user.model';
import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { CreateUserDto, DataLoginDefaultDto } from './users.dto';

export class UsersRepository extends AxiosFactory {
  constructor() {
    super('/users');
  }

  async createAccount(user_data: Partial<CreateUserDto>): Promise<CustomAxiosResponse<UserToken>> {
    return await this.axiosApi.post('/', { ...user_data });
  }

  async loginDefault(data_login: DataLoginDefaultDto): Promise<CustomAxiosResponse<UserToken>> {
    return await this.axiosApi.post('/login', { ...data_login });
  }

  async loginGoogle(sub_id_google: string): Promise<CustomAxiosResponse<UserToken>> {
    return await this.axiosApi.post('/login-google', { sub_id_google });
  }

  async getMe(): Promise<CustomAxiosResponse<UserModel>> {
    return await this.axiosApi.get('/me');
  }

  async updateContactData(data: Partial<UserModel>): Promise<CustomAxiosResponse<UserModel>> {
    return await this.axiosApi.put('/me', {
      ...data
    });
  }

  async updatePassword(last_password: string, new_password: string): Promise<CustomAxiosResponse<'user_password_changed'>> {
    return await this.axiosApi.put('/me/password', {
      last_password,
      new_password
    });
  }
  async updateMeLanguage(language: string): Promise<CustomAxiosResponse<UserModel>> {
    return await this.axiosApi.put('/me/language', {
      language
    });
  }

  async startRecoverPaswordByMail(email: string): Promise<CustomAxiosResponse<string>> {
    return await this.axiosApi.post('/remember-password', {
      user_email: email
    });
  }
  async verifyRememberPaswordKey(pasword_id: string): Promise<CustomAxiosResponse<string>> {
    return await this.axiosApi.get(`/remember-verify/${pasword_id}`);
  }
  async resetPaswordRecovery(new_password: string, password_recovery_id: string): Promise<CustomAxiosResponse<string>> {
    return await this.axiosApi.post('/reset-password', {
      new_password,
      password_recovery_id
    });
  }
}
