import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { SellProductModel } from '@/stores/types';

export class ProductsRepository extends AxiosFactory {
  constructor() {
    super('/products');
  }
  async getProducts(): Promise<CustomAxiosResponse<SellProductModel>> {
    return await this.axiosApi.get('/');
  }
}
