import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_TEAM } from '@/config/constants';
import { debounce } from 'lodash';
import { TeamModel } from './types';

const cleanTeamForm: TeamModel[] = [
  {
    name: '',
    surname: '',
    description: '',
    email: '',
    laboral_position: '',
    phone: '',
    image: '',
    id: Math.random()
  }
];

export const useTeamStore = defineStore('teamStore', () => {
  const teamForm: Ref<TeamModel[]> = useStorage(LOCALE_FORM_TEAM, cleanTeamForm);

  const setForm = (newForm, key) => {
    teamForm.value[key] = newForm;
  };
  const setFullForm = data => {
    teamForm.value = data.team;
  };
  const getForm = () => teamForm.value;

  const setTeamFormData = debounce(newData => {
    teamForm.value = newData;
  }, 1000);

  const $reset = () => {
    teamForm.value = cleanTeamForm;
  };

  return {
    teamForm,
    setForm,
    setTeamFormData,
    setFullForm,
    getForm,
    $reset
  };
});
