import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import type { TemplateConfigModel } from './types';
import { useStorage } from '@vueuse/core';
import { LOCALE_TEMPLATE_CONFG } from '@/config/constants';
import { useStateStore } from './StateStore';
const cleanForm = {
  template_id: 1,
  colors: { color1: '#efb810', color2: '#1f1f1f' },
  font: { text: 'Poppins', id: 4 },
  lang: 'en'
};
const colorOptions = {
  1: { color1: ['#003e77', '#00774f', '#aa3f3f', '#3f4faa', '#1f1f1f'], color2: ['#003e77', '#252525', '#474747', '#1f1f1f'] },
  2: { color1: ['#efb810', '#007099', '#00DA72', '#C65B59', '#7F7EE5', '#BBBBBB'], color2: ['#474747', '#003e77', '#252525', '#1f1f1f'] },
  3: { color1: ['#003e77', '#00774f', '#aa3f3f', '#3f4faa', '#d9ad26'], color2: ['#003e77', '#252525', '#474747', '#1f1f1f'] },
  4: { color1: ['#213845', '#001638', '#1f0000', '#664d00'], color2: ['#74A687', '#3888ff', '#ff3333', '#ffc71f'] }
};
const typoOptions = [
  {
    id: 0,
    text: 'Roboto'
  },
  {
    id: 1,
    text: 'Open'
  },
  {
    id: 2,
    text: 'Lato'
  },
  {
    id: 3,
    text: 'Montserrat'
  },
  {
    id: 4,
    text: 'Poppins'
  },
  {
    id: 5,
    text: 'Raleway'
  },
  {
    id: 6,
    text: 'Ubuntu'
  },
  {
    id: 7,
    text: 'Muli'
  },
  {
    id: 8,
    text: 'Rubik'
  },
  {
    id: 9,
    text: 'Hind'
  },
  {
    id: 10,
    text: 'Fjalla'
  },
  {
    id: 11,
    text: 'Maven'
  },
  {
    id: 12,
    text: 'Inter'
  },
  {
    id: 13,
    text: 'Cantarell'
  },
  {
    id: 14,
    text: 'Scada'
  },
  {
    id: 15,
    text: 'Playfair'
  },
  {
    id: 16,
    text: 'PT'
  },
  {
    id: 17,
    text: 'Quicksand'
  },
  {
    id: 18,
    text: 'Dosis'
  },
  {
    id: 19,
    text: 'Inconsolata'
  },
  {
    id: 20,
    text: 'Crimson'
  },
  {
    id: 21,
    text: 'Exo'
  }
];

export const useTemplateConfgigStore = defineStore('templateConfig', () => {
  const stateStore = useStateStore();
  const templateConfig: Ref<TemplateConfigModel> = useStorage(LOCALE_TEMPLATE_CONFG, cleanForm);
  const setForm = (key, newValue) => {
    templateConfig.value[key] = newValue;
  };

  const getTypoOptions = () => typoOptions;
  const getcolorOptions = () => colorOptions;
  const getForm = () => ({ ...templateConfig.value, font: templateConfig.value.font.text, lang: stateStore.lang });

  const setFullForm = newData =>
    (templateConfig.value = newData.font?.id
      ? newData
      : { ...newData, font: typoOptions.find(i => i.text === newData.font), lang: stateStore.lang });
  return { templateConfig, setForm, getForm, setFullForm, getcolorOptions, getTypoOptions };
});
