import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM } from '@/config/constants';
import { debounce } from 'lodash';
import { useExecutiveSummaryStore } from './executiveSummaryStore';
import { FormModel } from './types';
import { useClientTargetStore } from './clientStore';
import { useProductsStore } from './productsStore';
import { useIndustryStore } from './industryStore';
import { useFinancesStore } from './financesStore';
import { useTeamStore } from './teamStore';
import { useMarketingStore } from './marketingStore';
import { useOperationalPlanStore } from './operationalPlanStore';
import { useDafoStore } from './dafoStore';
import { useDepartmentsStore } from './departmentsStore';

const cleanForm = {
  new_company: false,
  coinType: null,
  organigram_image: ''
};

export const useFormStore = defineStore('formStore', () => {
  const executiveSummaryStore = useExecutiveSummaryStore();
  const clientTargetStore = useClientTargetStore();
  const industryStore = useIndustryStore();
  const productsStore = useProductsStore();
  const financesStore = useFinancesStore();
  const teamStore = useTeamStore();
  const marketingStore = useMarketingStore();
  const operationalPlanStore = useOperationalPlanStore();
  const dafoStore = useDafoStore();
  const departmentsStore = useDepartmentsStore();

  const form = useStorage(LOCALE_FORM, cleanForm);
  const formId: Ref<string> = useStorage('SavedId', '');
  const cover: Ref<string> = useStorage('Cover', '');
  const preview: Ref<string> = useStorage('Preview', '');

  const setForm = (newForm, key) => {
    form[key] = newForm;
  };
  const setFullForm = (newValue: FormModel) => {
    form.value = newValue;
    executiveSummaryStore.setFullForm(newValue);
    clientTargetStore.setFullForm(newValue);
    productsStore.setFullForm(newValue);
    industryStore.setFullForm(newValue);
    financesStore.setFullForm(newValue);
    teamStore.setFullForm(newValue);
    marketingStore.setFullForm(newValue);
    operationalPlanStore.setFullForm(newValue);
    dafoStore.setFullForm(newValue);
    departmentsStore.setFullForm(newValue);
  };
  const getForm = () => ({
    ...form.value,
    executiveSummary: executiveSummaryStore.getForm(),
    client_target: clientTargetStore.getForm(),
    industry: industryStore.getForm(),
    products: productsStore.getForm(),
    finances: financesStore.getForm(),
    team: teamStore.getForm(),
    marketing: marketingStore.getForm(),
    operational_plan: operationalPlanStore.getForm(),
    dafo: dafoStore.getForm(),
    departments: departmentsStore.getForm()
  });
  const setFormData = debounce((newData, subData?, subSubData?) => {
    if (subData) {
      if (subSubData) {
        form.value[subData][subSubData] = newData;
      } else form.value[subData] = newData;
    } else form.value = newData;
  }, 1000);

  const $reset = () => {
    formId.value = null;
    form.value = cleanForm;
    cover.value = '';
    preview.value = '';
    executiveSummaryStore.$reset();
    clientTargetStore.$reset();
    industryStore.$reset();
    productsStore.$reset();
    financesStore.$reset();
    teamStore.$reset();
    marketingStore.$reset();
    operationalPlanStore.$reset();
    dafoStore.$reset();
    departmentsStore.$reset();
  };
  const setId = newValue => (formId.value = newValue);

  const setPreview = data46 => (preview.value = data46);

  const setCover = data46 => (cover.value = data46);

  return {
    form,
    cover,
    preview,
    setForm,
    setFormData,
    setFullForm,
    getForm,
    $reset,
    formId,
    setId,
    setPreview,
    setCover
  };
});
