import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_DEPARTMENTS } from '@/config/constants';
import { debounce } from 'lodash';
import { DepartmentsModel } from './types';

const cleanDepartmentsForm: DepartmentsModel[] = [];

export const useDepartmentsStore = defineStore('departmentsStore', () => {
  const departmentsForm: Ref<DepartmentsModel[]> = useStorage(LOCALE_FORM_DEPARTMENTS, cleanDepartmentsForm);

  const setForm = (newForm, key) => {
    departmentsForm.value[key] = newForm;
  };
  const setFullForm = data => {
    departmentsForm.value = data.departments;
  };
  const getForm = () => departmentsForm.value;

  const setDepartmentsFormData = debounce(newData => {
    departmentsForm.value = newData;
  }, 1000);

  const $reset = () => {
    departmentsForm.value = cleanDepartmentsForm;
  };

  return {
    departmentsForm,
    setForm,
    setDepartmentsFormData,
    setFullForm,
    getForm,
    $reset
  };
});
