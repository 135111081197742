import { defineStore } from 'pinia';
import { watch } from 'vue';
import type { Ref } from 'vue';
import type { ExecutiveSumaryModel } from './types';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_EXECUTIVE_SUMMARY } from '@/config/constants';
import { debounce } from 'lodash';

const cleanExecutiveSummaryForm: ExecutiveSumaryModel = {
  com_name: '',
  constitution_date: { text: '', date: null },
  activity_date: { text: '', date: null },
  address: '',
  addressGoogle: null,
  legal_structure: null,
  sector: null,
  business_type: '',
  executive_summary: '',
  mision: '',
  vision: '',
  values: ''
};

export const useExecutiveSummaryStore = defineStore('executiveSummaryStore', () => {
  const executiveSummaryForm: Ref<ExecutiveSumaryModel> = useStorage(LOCALE_FORM_EXECUTIVE_SUMMARY, cleanExecutiveSummaryForm);

  const setForm = (newForm, key) => {
    executiveSummaryForm.value[key] = newForm;
  };
  const setFullForm = data => {
    executiveSummaryForm.value = data.executiveSummary;
  };
  const getForm = () => executiveSummaryForm.value;

  const setExecutiveSummaryFormData = debounce((newData, subData?) => {
    if (subData) {
      executiveSummaryForm.value[subData] = newData;
    } else executiveSummaryForm.value = newData;
  }, 1000);

  watch(
    executiveSummaryForm.value,
    () => {
      executiveSummaryForm.value.address = executiveSummaryForm.value.addressGoogle?.formatted_address;
    },
    { deep: true, immediate: true }
  );

  const $reset = () => {
    executiveSummaryForm.value = cleanExecutiveSummaryForm;
  };

  return {
    executiveSummaryForm,
    setForm,
    setExecutiveSummaryFormData,
    setFullForm,
    getForm,
    $reset
  };
});
