import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e3ff648"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-container" }
const _hoisted_2 = { class: "group-links" }
const _hoisted_3 = { class: "link" }
const _hoisted_4 = { class: "link" }
const _hoisted_5 = { class: "link" }
const _hoisted_6 = {
  key: 0,
  class: "group-buttons w-fit"
}
const _hoisted_7 = {
  key: 1,
  class: "buttons-loder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: '/#features' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('NavbarMenu.features')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: '/#pricing' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('NavbarMenu.pricing')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: '/#magazine' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('NavbarMenu.know_us')), 1)
        ]),
        _: 1
      })
    ]),
    (!$setup.isLoged)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_router_link, { to: {name:"SignIn"} }, {
            default: _withCtx(() => [
              _createVNode($setup["AppButtonOutlined"], null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('NavbarMenu.sign_in_btn')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_router_link, { to: {name:"MyBPlans"} }, {
            default: _withCtx(() => [
              _createVNode($setup["AppButton"], null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Dashboard')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
  ]))
}