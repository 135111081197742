
import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
export interface input {
  content:string;
  business_plan_id: string |null;
  business_plan_cover:string|null;
}

export class PdfGeneratorRepository extends AxiosFactory {
  constructor() {
    super('/generate');
  }

  async generatePdfPreview({content, business_plan_id, business_plan_cover}:input):Promise<CustomAxiosResponse<string>> {
  
    return await this.axiosApi.post('/', {content, business_plan_id: business_plan_id === 'null'? null : business_plan_id, business_plan_cover});
  }
  
}