import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e371f3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "box-menu"
}
const _hoisted_2 = { class: "menu-links-holder" }
const _hoisted_3 = { class: "link" }
const _hoisted_4 = { class: "link" }
const _hoisted_5 = { class: "link" }
const _hoisted_6 = {
  key: 0,
  class: "buttons-holder"
}
const _hoisted_7 = {
  key: 1,
  class: "buttons-loder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XyzTransition = _resolveComponent("XyzTransition")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "menu-holder",
      onClick: $setup.switchMenu
    }, [
      _createVNode($setup["iconMenuVue"])
    ]),
    _createVNode(_component_XyzTransition, { xyz: "fade duration-3" }, {
      default: _withCtx(() => [
        ($setup.openMenu)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "background-overlay",
              onClick: $setup.switchMenu
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_XyzTransition, { xyz: "fade left duration-3" }, {
      default: _withCtx(() => [
        ($setup.openMenu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "close-menu",
                  onClick: $setup.switchMenu
                }, [
                  _createVNode($setup["IconClose"])
                ]),
                _createVNode(_component_router_link, {
                  to: "/#features",
                  onClick: $setup.switchMenu
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('NavbarMenu.features')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/#pricing",
                  onClick: $setup.switchMenu
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('NavbarMenu.pricing')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/#magazine",
                  onClick: $setup.switchMenu
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('NavbarMenu.know_us')), 1)
                  ]),
                  _: 1
                }),
                (!$setup.authStore.userIsLoged)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_router_link, { to: {name:"SignIn"} }, {
                        default: _withCtx(() => [
                          _createVNode($setup["AppButtonOutlined"], null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('NavbarMenu.sign_in_btn')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_router_link, { to: {name:"MyBPlans"} }, {
                        default: _withCtx(() => [
                          _createVNode($setup["AppButton"], null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Dashboard')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}