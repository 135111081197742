import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c1d292a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-secondary-clear" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "magazine-holder" }
const _hoisted_4 = { class: "content-magazine" }
const _hoisted_5 = { class: "section-magazine" }
const _hoisted_6 = { class: "img" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "section-magazine" }
const _hoisted_9 = { class: "img" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "section-magazine" }
const _hoisted_12 = { class: "img" }
const _hoisted_13 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["HomeSectionTitles"], { class: "w-full" }, {
          chimp: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('HomeMagazine.chimp')), 1)
          ]),
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('HomeMagazine.title')), 1)
          ]),
          subtitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('HomeMagazine.subtitle')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Vue3Lottie, {
                class: "lottie",
                animationData: $setup.accessLottie
              }, null, 8, ["animationData"])
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('HomeMagazine.text_1')), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Vue3Lottie, {
                class: "lottie higther",
                animationData: $setup.strategyLottie
              }, null, 8, ["animationData"])
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('HomeMagazine.text_2')), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Vue3Lottie, {
                class: "lottie",
                animationData: $setup.buildLottie
              }, null, 8, ["animationData"])
            ]),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('HomeMagazine.text_3')), 1)
          ])
        ]),
        _createVNode(_component_router_link, { to: {name:"SelectTemplate"} }, {
          default: _withCtx(() => [
            _createVNode($setup["AppButton"], { class: "button-style" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('HomeMagazine.button')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}