import { UserModel } from '@/models/user.model';
import { useStorage } from '@vueuse/core';
import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { LOCALE_ID_LANG } from '@/config/constants';

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      user_email: null as UserModel['user_email'],
      user_name: null as UserModel['user_name'],
      user_post_name: null as UserModel['user_post_name'],
      user_language: useStorage(LOCALE_ID_LANG, window.navigator.language.toLowerCase().slice(0, 2)) as Ref<UserModel['user_language']>,
      user_profile_image: null as UserModel['user_profile_image'],
      user_account_status: null as UserModel['user_account_status'],
      user_auth_provider: null as UserModel['user_auth_provider']
    };
  },
  getters: {
    getFullName: state => {
      if (state.user_name && state.user_post_name) return `${state.user_name} ${state.user_post_name}`;
      if (state.user_name && !state.user_post_name) return `${state.user_name}`;
      if (!state.user_name && state.user_post_name) return `${state.user_post_name}`;
      return null;
    },
    haveProfileImage: state => !!state.user_profile_image,
    getLiteNameOrProfileImage: state => {
      let liteName: string = null;
      if (state.user_profile_image && !isEmpty(state.user_profile_image)) return state.user_profile_image;
      if (state.user_name && state.user_post_name) liteName = `${state.user_name[0]}${state.user_post_name[0]}`;
      if (state.user_name && !state.user_post_name) liteName = `${state.user_name[0]}${state.user_name[1]}`;
      if (!state.user_name && !state.user_post_name && state.user_email) liteName = `${state.user_email[0]}${state.user_email[1]}`;
      if (liteName) return liteName.toUpperCase();
      return null;
    },
    getAccountStatus: state => `${state.user_account_status}`,
    getAuthProvider: state => `${state.user_auth_provider}`
  },
  actions: {
    setDataUser(userData: UserModel) {
      this.user_email = userData.user_email;
      this.user_language = userData.user_language;
      this.user_name = userData.user_name;
      this.user_post_name = userData.user_post_name;
      this.user_account_status = userData.user_account_status;
      this.user_profile_image = userData.user_profile_image;
      this.user_auth_provider = userData.user_auth_provider;
    },
    $reset() {
      this.user_email = null;
      this.user_language = null;
      this.user_name = null;
      this.user_post_name = null;
      this.user_account_status = null;
      this.user_profile_image = null;
      this.user_auth_provider = null;
    }
  }
});
