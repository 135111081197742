import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71a3ff22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "features-holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["HomeSectionTitles"], null, {
        chimp: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('HomeFeatures.chimp')), 1)
        ]),
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('HomeFeatures.title')), 1)
        ]),
        subtitle: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('HomeFeatures.subtitle')), 1)
        ]),
        _: 1
      }),
      _createVNode($setup["HomeFeaturesGrid"])
    ])
  ]))
}