export const LOCAL_ID_KEY = 'UUID';
export const LOCALE_ID_LANG = 'business_locale';
export const LINK_WEB_PUBLIC_LINK = 'businessplan.app/file/';
export const USER_TOKEN = 'USER_TOKEN_R2B';
export const LOCALE_TEMPLATE_CONFG = 'Template_config_R2B';
export const LOCALE_FORM = 'BPFormLocal';
export const LOCALE_FORM_EXECUTIVE_SUMMARY = 'R2BExecutiveSummaryForm';
export const LOCALE_FORM_CLIENT_TARGET = 'R2BClientTargetForm';
export const LOCALE_FORM_INDUSTRY = 'R2BIndustryForm';
export const LOCALE_FORM_PRODUCTS = 'R2BProductsForm';
export const LOCALE_FORM_FINANCES = 'R2BFinancesForm';
export const LOCALE_FORM_TEAM = 'R2BTeamForm';
export const LOCALE_FORM_MARKETING = 'R2BMarketingForm';
export const LOCALE_FORM_OPERATIONAL_PLAN = 'R2BOperationalPlanForm';
export const LOCALE_FORM_DAFO = 'R2BDAFOForm';
export const LOCALE_FORM_DEPARTMENTS = 'R2BDepartmentsForm';
export const STRIPE_PUBLIC_KEY =
  'pk_test_51MTO2oJ9q929ThcFg8Yr7cbFm9xz0t36YXuVJYi8ahYvEptR3DFTyVaCN5WRknErhABZPq6bk5GvOIEH6YmUxQ5G00CHki3ZAq';
export const LANGUAGES = [
  { text: 'Español', value: 'es', id: 0 },
  { text: 'English', value: 'en', id: 1 },
  { text: 'Italiano', value: 'it', id: 2 },
  { text: 'Français', value: 'fr', id: 3 },
  { text: 'Deutsch', value: 'de', id: 4 }
];
export const ACCEPTED_COUNTRIES_GPAY = [
  'AE',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CI',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DO',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GI',
  'GR',
  'GT',
  'HK',
  'HR',
  'HU',
  'ID',
  'IE',
  'IN',
  'IT',
  'JP',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PE',
  'PH',
  'PL',
  'PT',
  'RO',
  'SE',
  'SG',
  'SI',
  'SK',
  'SN',
  'TH',
  'TT',
  'US',
  'UY'
];
