import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_OPERATIONAL_PLAN } from '@/config/constants';
import { debounce } from 'lodash';
import { OperationalPlanModel } from './types';

const cleanOperationalPlanForm: OperationalPlanModel[] = [
  {
    title: '',
    subtitle: '',
    numberRange: '',
    timeSet: null,
    list: [],
    id: Math.random()
  }
];

export const useOperationalPlanStore = defineStore('operationalPlanStore', () => {
  const operationalPlanForm: Ref<OperationalPlanModel[]> = useStorage(LOCALE_FORM_OPERATIONAL_PLAN, cleanOperationalPlanForm);

  const setForm = (newForm, key) => {
    operationalPlanForm.value[key] = newForm;
  };
  const setFullForm = data => {
    operationalPlanForm.value = data.operational_plan;
  };
  const getForm = () => operationalPlanForm.value;

  const setOperationalPlanFormData = debounce(newData => {
    operationalPlanForm.value = newData;
  }, 1000);

  const $reset = () => {
    operationalPlanForm.value = cleanOperationalPlanForm;
  };

  return {
    operationalPlanForm,
    setForm,
    setOperationalPlanFormData,
    setFullForm,
    getForm,
    $reset
  };
});
