import { App } from 'vue';
import { BusinessPlanRepository } from './business_plan/business_plan.repository';
import { PdfGeneratorRepository } from './generator/generator.repository';
import { UsersRepository } from './users/users.repository';
import { PaymentsRepository } from './payments/payments.repository';
import { ProductsRepository } from './products.repository.ts/products.repository';

export default {
  install: (app: App) => {
    app.provide('UserRepository', new UsersRepository());
    app.provide('BusinessPlanRepository', new BusinessPlanRepository());
    app.provide('PdfGeneratorRepository', new PdfGeneratorRepository());
    app.provide('PaymentsRepository', new PaymentsRepository());
    app.provide('ProductsRepository', new ProductsRepository());
  }
};
