import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80006170"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "float-menu-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XyzTransition = _resolveComponent("XyzTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "action-button",
      ref: "buttonMenu",
      onClick: $setup.toggleMenuVisibility
    }, [
      _renderSlot(_ctx.$slots, "action-button", {}, undefined, true)
    ], 512),
    _createVNode(_component_XyzTransition, { xyz: "fade up-1 duration-2" }, {
      default: _withCtx(() => [
        ($setup.customShowMenu)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["float-menu", [$setup.position, $setup.positionX, $setup.width]]),
              ref: "blockMenu"
            }, [
              _createVNode($setup["BaseCardMenuFloatWrapper"], null, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
              })
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}