import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64c6ebe6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XyzTransition = _resolveComponent("XyzTransition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-wrapper", [{ active: $setup.inputActive, error: $props.error, disabled:$props.disabled }]])
  }, [
    _renderSlot(_ctx.$slots, "label", {}, undefined, true),
    _createElementVNode("div", {
      class: _normalizeClass(["input-box", ['bg-'+$setup.inputColor, $props.inputBorderColor]])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createVNode(_component_XyzTransition, { xyz: "fade up-1 out-right-1 out-up-0 in-duration-1" }, {
      default: _withCtx(() => [
        ($props.error && $props.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.errorMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}