import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["button-primary", [{'with-icon': $setup.showIcon}]])
  }, [
    ($setup.showIcon && $setup.iconPosition == 'left')
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.iconComponent), {
          key: 0,
          class: "icon-left"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    ($setup.showIcon && $setup.iconPosition == 'right')
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.iconComponent), {
          key: 1,
          class: "icon-right"
        }))
      : _createCommentVNode("", true)
  ], 2))
}