import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_FORM_INDUSTRY } from '@/config/constants';
import { debounce } from 'lodash';
import { IndustryModel } from './types';

const cleanIndustryForm: IndustryModel = {
  n_customers: '',
  spend_costumers: '',
  market_size: null,
  competences: [],
  resume: ''
};

export const useIndustryStore = defineStore('industryStore', () => {
  const industryForm: Ref<IndustryModel> = useStorage(LOCALE_FORM_INDUSTRY, cleanIndustryForm);

  const setForm = (newForm, key) => {
    industryForm.value[key] = newForm;
  };
  const setFullForm = data => {
    industryForm.value = data.industry;
  };
  const getForm = () => industryForm.value;

  const setIndustryFormData = debounce(newData => {
    industryForm.value = newData;
  }, 1000);

  const $reset = () => {
    industryForm.value = cleanIndustryForm;
  };

  return {
    industryForm,
    setForm,
    setIndustryFormData,
    setFullForm,
    getForm,
    $reset
  };
});
