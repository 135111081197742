import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { LOCALE_ID_LANG } from '@/config/constants';

export const useStateStore = defineStore('state', () => {
  const loadedScript = ref(false);
  const loadedStripeScript = ref(false);
  const lang = useStorage(LOCALE_ID_LANG, 'en');
  const setLang = newLang => {
    lang.value = newLang;
  };
  const setLoadedStripeScript = (newStatus: boolean) => (loadedStripeScript.value = newStatus);

  return { loadedScript, lang, setLang, loadedStripeScript, setLoadedStripeScript };
});
